import React from 'react';
import { Seo } from '../components/seo';
import { Layout } from '../layout';
import { BreadCrumb } from '../section/breadcrumb';

import LOGO_RESTAURANT_INTERIOR from '../assets/svg/logo-restaurant.svg';
import RESTAURANT_INTERIOR from '../assets/img/restaurant-interior.jpeg';
import RESTAURANT_INTERIOR_IMG from '../assets/img/bg-restaurant-interior.jpeg';

const RestaurantInteriorPage = () => {
  return (
    <Layout>
      <Seo
        title="飲食店舗"
        description="店舗を訪れるお客様が、心地よく時間を過ごせるように。またスタッフの方々が、いつも笑顔でスムーズに働けるように。緻密にコミュニケーションを取り合い、お客様のこだわりをFINEの熟練の職人がカタチにします。"
      />
      {/* <!-- ヘッダータイトル --> */}
      <section className="mb-6">
        <div
          style={{ backgroundImage: `url(${RESTAURANT_INTERIOR_IMG})` }}
          className="
            relative
            flex
            items-center
            justify-center
            2xl:-mt-2
            w-screen
            h-40vh
             bg-cover bg-center
            overflow-hidden
            md:h-60vh
          "
        >
          <h1 className="flex flex-col items-center justify-center tracking-widest">
            <span className="text-white text-2xl md:text-4xl">
              RESRAURANT INTERIOR
            </span>
            <span className="mt-2 text-white text-xs md:text-base">
              飲食店舗
            </span>
          </h1>
          <div>
            <svg
              className="
                wave
                absolute
                bottom-0
                inset-x-0
                -mb-1
                w-200vw
                text-white
                fill-current
              "
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      {/* <!-- 現在ページ --> */}
      <BreadCrumb title="飲食店舗" />
      {/* <!-- ご挨拶 --> */}
      <section className=" flex flex-wrap mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-5xl">
        <div className=" flex flex-col items-center justify-center mb-12 w-full lg:mb-20 lg:pr-6 lg:w-1/2">
          <div className="mb-3 md:mb-6">
            <img
              className="w-16 md:w-24"
              src={LOGO_RESTAURANT_INTERIOR}
              alt=""
            />
          </div>
          <div className="mb-8 w-full md:mb-12">
            <h2 className="text-primary-blue md:text-2xl md:leading-loose">
              失敗しないお店創りのお手伝い
              <br />
              をさせて頂きます
            </h2>
          </div>
          <div>
            <p className="text-xs tracking-widest leading-5 md:text-sm md:leading-7">
              店舗を訪れるお客様が、心地よく時間を過ごせるように。またスタッフの方々が、いつも笑顔でスムーズに働けるように。
              <br />
              緻密にコミュニケーションを取り合い、お客様のこだわりをFINEの熟練の職人がカタチにします。
            </p>
          </div>
        </div>
        <div
          className="relative flex items-start justify-center w-full lg:pl-6 lg:w-1/2
          "
        >
          <div className="mx-auto w-3/4 md:w-2/3 lg:w-full">
            <img
              className="w-full object-cover"
              src={RESTAURANT_INTERIOR}
              alt=""
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RestaurantInteriorPage;
